import React from "react";
import { Container, Carousel } from "react-bootstrap";
import "../../App.css";

function SolidWorks() {
  return (
    <Container style={{ maxWidth: "60%" }}>
      <div className="row">
        <p></p>
        <h1> SolidWorks </h1>
        <p></p>
        <p></p>
        <div className="col">
          <h5>
            {" "}
            <b>Schulungsthemen </b>
          </h5>
          <ul>
            <li>Basis-Schulung</li>
            <li>Aufbau-Schulung</li>
            <li>Freiformflächen</li>
            <li>Update-Schulungen</li>
            <li>Blech-Konstruktion</li>
          </ul>
          <h5>Weitere Spezialthemen auf Anfrage</h5>
        </div>
        <div className="col">
          <h5>
            Beispiel: <i>In Kürze verfügbar</i>
          </h5>
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="..."
                alt="In Kürze verfügbar"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="..."
                alt="In Kürze verfügbar"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="..."
                alt="In Kürze verfügbar"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="..."
                alt="In Kürze verfügbar"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div class="spacer"></div>
    </Container>
  );
}

export default SolidWorks;
