import { Form, Container, Button, NavDropdown } from "react-bootstrap";

function Spezialgebiete() {
  return (
    <Container style={{ padding: "1.5rem" }}>
      <h1>Spezialgebiete</h1>
      <br />
      <p>
        Schulungen für <b>unterschiedliche Hintergründe:</b>{" "}
      </p>
      <ul>
        <li>Firmenschulungen</li>
        <li>akademische Einrichtungen wie Berufsschulen und Universitäten</li>
        <li>Weiterbildungsinstitute</li>
      </ul>
      <p>
        Dank meines Studiums der <b>Erwachsenenbildung</b> (Andragogik) habe ich
        außerdem Kompetenz und Erfahrung in folgenden Themen:{" "}
      </p>
      <ul>
        <li>Umgang mit "schwierigen" Teilnehmern</li>
        <li>Erarbeitung teilnehmerorientierter Curiccula</li>
        <li>Individuelle Lückenanalyse</li>
        <li>
          Mehrjährige Erfahrung in <b> E-Learning/Blended Learning </b>{" "}
          Szenarien
        </li>
        <li style={{ marginLeft: "50px" }}>Motivationserhaltung/-Steigerung</li>
        <li style={{ marginLeft: "50px" }}>
          Genutzte Technologien: Virtuelle Lernumgebungen, Zoom, Teams, Moodle,
          Jitsi, WBS Learnspace{" "}
        </li>
      </ul>
      <div class="spacer"></div>
    </Container>
  );
}

export default Spezialgebiete;
