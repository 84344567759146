import React from "react";
import { Container } from "react-bootstrap";
import "../../App.css";

function ELearning() {
  return (
    <Container>
      <div> Mehr Informationen folgen! </div>
    </Container>
  );
}

export default ELearning;
