import React from "react";
import { Container, Carousel } from "react-bootstrap";
import "../../App.css";

function NX() {
  return (
    <Container style={{ maxWidth: "60%" }}>
      <div className="row">
        <p></p>
        <h1> Siemens NX </h1>
        <p></p>
        <p></p>
        <div className="col">
          <h5>
            {" "}
            <b>Schulungsthemen </b>
          </h5>
          <ul>
            <li>Basis-Schulung</li>
            <li>Aufbau-Schulung</li>
            <li>Freiformflächen</li>
            <li>Synchrone Konstruktion</li>
            <li>Update-Schulungen</li>
            <li>Datenaustausch/Umgang mit konvertierten Daten</li>
            <li>3D-Druck Einführung</li>
            <li>3D-Druck FDM mit Cura</li>
          </ul>
          <h5>Weitere Spezialthemen auf Anfrage</h5>
        </div>
        <div className="col">
          <h5>
            Beispiel:{" "}
            <i>
              Curriculum Basisschulung Siemens NX für TPD-Azubis (Dauer 3
              Wochen)
            </i>
          </h5>
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/nx-schulung/nx-inhalt-1.png"
                alt="Hier können Sie mit einem passendem Browser die Gliederung einer Schulung betrachten"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/nx-schulung/nx-inhalt-2.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/nx-schulung/nx-inhalt-3.png"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/nx-schulung/nx-inhalt-4.png"
                alt="Last slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div class="spacer"></div>
    </Container>
  );
}

export default NX;
