import React from "react";
import "../../App.css";

function Home() {
  return (
    <div className="container px-4 px-lg-5">
      <div className="row gx-4 gx-lg-5 align-items-center my-5">
        <div className="col-lg-4">
          <img
            className="img-fluid rounded mb-4 mb-lg-0 mx-auto d-block"
            src="./portrait.jpg"
            alt="..."
          />
        </div>
        <div className="col-lg-8">
          <h1 className="font-weight-light">
            Wiederaufnahme der freiberuflichen Tätigkeit
          </h1>
          <p style={({ "font-size": "1.3rem" }, { "margin-bottom": "1.3rem" })}>
            Liebe Kunden <br />
            Ich habe mich entschlossen meine freiberufliche Tätigkeit 2021
            wieder aufzunehmen. <br /> Viele Erfahrungen und die Kenntnisse über
            ein zusätzliches CAD-System (SolidWorks) reicher, biete ich wieder
            Schulungen in Präsenz oder auch online an.
            <br />
            Ich freue mich auf Ihre Anfragen.
          </p>
          <a className="btn btn-primary" href="/kontakt">
            Kontaktieren Sie mich hier!
          </a>
        </div>
      </div>
      <div className="card text-white bg-secondary my-5 py-2 text-center">
        <div className="card-body">
          <p className="text-white m-0" style={{ "font-size": "2rem" }}>
            Schulungen in Präsenz oder Online!
          </p>
        </div>
      </div>
      <div className="row gx-4 gx-lg-4">
        <div className="col-md-4 mb-5">
          <div className="card h-100">
            <div className="card-body">
              <h2 className="card-title">Siemens NX</h2>
              <p className="card-text">
                Dieses High-End CAD-System von Siemens kann die komplette
                Prozesskette einer Firma abdecken. Mein Favorit für
                Freiformflächen und synchrone Konstruktion.
              </p>
            </div>
            <div className="card-footer">
              <a className="btn btn-primary btn-sm" href="/nx">
                Schulungsdetails
              </a>
              <a
                className="btn btn-primary btn-sm"
                href="https://www.plm.automation.siemens.com/global/de/products/nx/"
              >
                Zum Hersteller
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <div className="card h-100">
            <div className="card-body">
              <h2 className="card-title">Solid Edge</h2>
              <p className="card-text">
                Dieses System ist besonders nutzerfreundlich und einfach zu
                bedienen. Vielseitig, aber etwas weniger mächtig verglichen mit
                NX.
              </p>
            </div>
            <div className="card-footer">
              <a className="btn btn-primary btn-sm" href="/solid-edge">
                Schulungsdetails
              </a>
              <a
                className="btn btn-primary btn-sm"
                href="https://solidedge.siemens.com/de/"
              >
                Zum Hersteller
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <div className="card h-100">
            <div className="card-body">
              <h2 className="card-title">SolidWorks</h2>
              <p className="card-text">
                Konkurrenzprodukt zu Solid Edge mit vergleichbarem
                Funktionsumfang. Das bekannteste Mid-Range System.
              </p>
            </div>
            <div className="card-footer">
              <a className="btn btn-primary btn-sm" href="/solid-works">
                Schulungsdetails
              </a>
              <a
                className="btn btn-primary btn-sm"
                href="https://www.solidworks.com/de"
              >
                Zum Hersteller
              </a>
            </div>
          </div>
        </div>
        <br></br>
        <div class="spacer"></div>
      </div>
    </div>
  );
}

export default Home;
