import { Container } from "react-bootstrap";

function Kontakt() {
  return (
    <Container style={({ "margin-top": "1rem" }, { maxWidth: "50%" })}>
      <br></br>
      <h1 style={{ textAlign: "center" }}> Kontakt </h1>
      <h4 style={{ textAlign: "center" }}>
        {" "}
        Ob Anfragen für Schulungen, oder Beratungen zwecks CAD-Systemen,
        schreiben Sie mir gerne!
      </h4>
      <div>
        {" "}
        <br />{" "}
      </div>
      <div style={{ textAlign: "center" }}>
        <a href="mailto:s.adams@cad-adams.de">s.adams@cad-adams.de</a>
      </div>
      <div class="spacer"></div>
    </Container>
  );
}

export default Kontakt;
