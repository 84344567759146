import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

function MyNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/">CAD-Schulung und Beratung</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Kursangebote" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/nx">NX</NavDropdown.Item>
              <NavDropdown.Item href="/solid-edge">Solid Edge</NavDropdown.Item>
              <NavDropdown.Item href="/solid-works">
                Solid Works
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/e-learning">E-Learning</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/spezialgebiete">Spezialgebiete</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/Kontakt">Kontakt</Nav.Link>
            <Nav.Link eventKey={2} href="/Impressum">
              Impressum
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
