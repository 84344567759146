import "./App.css";
import MyNavbar from "./components/Navbar";
import MyFooter from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Nx from "./components/pages/Nx";
import SolidEdge from "./components/pages/Solid-Edge";
import SolidWorks from "./components/pages/Solid-Works";
import ELearning from "./components/pages/E-Learning";
import Kontakt from "./components/pages/Kontakt";
import Impressum from "./components/pages/Impressum";

import "bootstrap/dist/css/bootstrap.min.css";
import Spezialgebiete from "./components/pages/Spezialgebiete";

function App() {
  return (
    <>
      <Router>
        <MyNavbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/nx" element={<Nx />} />
          <Route path="/solid-edge" element={<SolidEdge />} />
          <Route path="/solid-works" element={<SolidWorks />} />
          <Route path="/e-learning" element={<ELearning />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/spezialgebiete" element={<Spezialgebiete />} />
        </Routes>
        <MyFooter />
      </Router>
    </>
  );
}

export default App;
