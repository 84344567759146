import React, {useState, useEffect} from 'react'



function MyFooter() {
    return(
        <div className="fixed-bottom">
        <footer className="py-3 bg-dark">
            <div className="container px-4 px-lg-5"><p className="m-0 text-center text-white"> &copy; 2021 Sabine Adams - s.adams@cad-adams.de</p></div>
        </footer>
        </div>
    )
}

export default MyFooter;
